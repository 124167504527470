import { render, staticRenderFns } from "./editPlace.vue?vue&type=template&id=268b3054&scoped=true&"
import script from "./editPlace.vue?vue&type=script&lang=js&"
export * from "./editPlace.vue?vue&type=script&lang=js&"
import style0 from "./editPlace.vue?vue&type=style&index=0&id=268b3054&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "268b3054",
  null
  
)

export default component.exports